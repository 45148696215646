<script setup lang="ts">
import type { Category } from "~/domain/entity";
import { uiCategoryGrid } from "~/components/category/grid/ui-category-grid";

const props = defineProps<{
  categoryList: Category[];
  tiny?: boolean;
}>();

const ui = computed(() => uiCategoryGrid(props?.tiny));
</script>

<template>
  <div>
    <UContainer>
      <div :class="ui.grid">
        <CategoryGridItem
          v-for="category in props.categoryList"
          :key="category.id"
          :category="category"
          :tiny="props.tiny"
        />
      </div>
    </UContainer>
  </div>
</template>

<style scoped></style>
