import { twMerge } from "tailwind-merge";

export const uiCategoryGrid = (tiny?: boolean) => {
  let grid = "";

  if (tiny) {
    grid = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4";
  }

  return {
    grid: twMerge(
      "grid grid-cols-2 gap-2 mi:gap-3 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
      grid,
    ),
  };
};
